// import {environment} from "../../environments/environment";
// (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
//     (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
//     m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
//     })(window,document,'script','./analytics.js','ga');

//     ga('create', 'UA-228216417-1', 'auto');
//     // ga('set', 'page', window.location.href);
//     // ga('send', 'pageview');

export function addGoogleAnalytic(id){
    // debugger;
    // $("head").append($("<script></script>").attr("src", "./analytics.js"));
    try {
        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
            (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
            m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
            })(window,document,'script','./analytics.js','ga');
        
            ga('create', id, 'auto');
            // ga('set', 'page', window.location.href);
            // ga('send', 'pageview');
      } catch (ex) {
        console.error('Error appending google analytics');
        console.error(ex);
      }
}