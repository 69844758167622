import { Injectable } from '@angular/core';
import { switchMap, tap, catchError } from 'rxjs/operators';
import {
  HttpRequest,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpEvent,
} from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptorService {

  constructor(private route: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(request).pipe(
      tap(),
      catchError((err) => {
        if (err.status === 401) {
          /////Code to catch all exceptions
        } else if (err.status === 400) {
          if (err['error']['errorcode'] == 9990) {
            this.route.navigate(['/account/login']);
            return;
          }
        }
        return throwError(err);
      })
    );
  }
}

export class TokenInterceptor implements HttpInterceptor {

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const userToken ='';
    // const deviceId = 'hrushi_device_1';
    if (req.url === 'https://jsonip.com') {
      const modifiedReq = req.clone();
      return next.handle(modifiedReq);

    } else if ((req.method === "POST" && req.url == "") || (req.method === "PUT" && (req.url == ""
      || req.url == ""))) {
      const modifiedReq = req.clone({
        headers: req.headers
          .set('Authorization', `Bearer ${userToken}`)
      });
      return next.handle(modifiedReq);
    }
    else {
      const modifiedReq = req.clone({
        headers: req.headers
          .set('Authorization', `Bearer ${userToken}`)
      });
      return next.handle(modifiedReq);
    }
  }
}
