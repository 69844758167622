import { Injectable } from '@angular/core';

import { Router, NavigationEnd } from '@angular/router';
import { environment } from '.././../environments/environment';
import * as gaFileCode from '../../assets/js/gaScript.js';

declare var ga: Function;

@Injectable()
export class GoogleAnalyticsService {

  constructor(router: Router) { 
    // this.appendGaTrackingCode(environment.GA_TRACKING_ID);
    gaFileCode.addGoogleAnalytic(environment.GA_TRACKING_ID);
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        ga('set', 'page', event.url);
        ga('send', 'pageview');
      }
    });
  }

  // public appendGaTrackingCode(id) {
  //   try {
  //     const script = document.createElement('script');
  //     script.innerHTML =
  //       `
  //       (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
  //       (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
  //       m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
  //       })(window,document,'script','../../assets/js/analytics.js','ga');

  //       ga('create', '`+ id +`', 'auto');
  //     `;
  //     document.head.appendChild(script);
  //   } catch (ex) {
  //     console.error('Error appending google analytics');
  //     console.error(ex);
  //   }
  // }

  //create our event emitter to send our data to Google Analytics
   public eventEmitter(eventCategory: string,
                   eventAction: string,
                   eventLabel: string = null,
                   eventValue: number = null) {
    ga('send', 'event', {
      eventCategory: eventCategory,
      eventLabel: eventLabel,
      eventAction: eventAction,
      eventValue: eventValue,
    });

                   }

}