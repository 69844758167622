import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatListModule} from '@angular/material/list';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 
import {MatChipsModule} from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSelectModule} from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import {MatTabsModule} from '@angular/material/tabs';
import { FormsModule } from '@angular/forms';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatMenuModule} from '@angular/material/menu';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { ReactiveFormsModule } from '@angular/forms';



@NgModule({
    imports: [
        MatCardModule,
        MatButtonModule,
        MatToolbarModule,
        MatIconModule,
        MatDividerModule,
        MatListModule,
        MatExpansionModule,
        MatTableModule,
        BrowserAnimationsModule,
        MatDividerModule,
        MatIconModule,
        MatChipsModule,
        MatStepperModule,
        MatFormFieldModule,
        MatInputModule,
        MatRadioModule,
        MatDialogModule,
        MatSelectModule,
        MatPaginatorModule,
        MatSortModule,
        MatTabsModule,
        FormsModule,
        MatSnackBarModule,
        MatBottomSheetModule,
        MatMenuModule,
        MatCheckboxModule,
        ReactiveFormsModule
    ],
    exports: [
        MatCardModule,
        MatButtonModule,
        MatToolbarModule,
        MatIconModule,
        MatDividerModule,
        MatListModule,
        MatExpansionModule,
        MatTableModule,
        BrowserAnimationsModule,
        MatDividerModule,
        MatIconModule,
        MatChipsModule,
        MatStepperModule,
        MatFormFieldModule,
        MatInputModule,
        MatRadioModule,
        MatDialogModule,
        MatSelectModule,
        MatPaginatorModule,
        MatSortModule,
        MatTabsModule,
        FormsModule,
        MatSnackBarModule,
        MatBottomSheetModule,
        MatMenuModule,
        MatCheckboxModule,
        ReactiveFormsModule
    ],
})
export class CustomMaterialModule {}
