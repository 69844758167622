import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-general-layout',
  templateUrl: './general-layout.component.html',
  styleUrls: ['./general-layout.component.css']
})
export class GeneralLayoutComponent implements OnInit {
  toggleFlag: boolean = false;
  sidebarflag: any;

  constructor(private sharedService: SharedService) {
    this.sharedService.toggleFlag.subscribe(
      (flag: boolean) => {
        // if (flag) {
          this.toggleFlag = flag;
        // }
      });
      // this.sidebarflag=this.sharedService.glbFlags['mobileSideBar']
      this.sidebarflag=this.sharedService.glbFlags
  }


  
  ngOnInit(): void {
    // this.sidebarflag=this.sharedService.glbFlags['mobileSideBar']
    this.sidebarflag=this.sharedService.glbFlags
  }
  closePanels(event) {
    event.stopPropagation();
    // this.sharedService.glbFlags['mobileSideBar'] = false;
    this.sharedService.glbFlags = false;
  }

}
