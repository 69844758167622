import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from '../environments/environment';

declare const gtag: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  title = 'KeyAccounts';
  loading: boolean = false;

  constructor(private router: Router) {

    /*this.addGAScript();
    this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
        gtag('event', 'page_view', {
            page_path: event.urlAfterRedirects
        })
        
    }) */
}/** Add Google Analytics Script Dynamically */
/* addGAScript() {
  //console.log("hi... load GA");
  let gtagScript: HTMLScriptElement = document.createElement('script');
  gtagScript.async = true;
  //gtagScript. = 'self';
  //https://www.google-analytics.com/analytics.js
  gtagScript.type = 'text/javascript';
  //gtagScript.charset = 'utf-8';
  gtagScript.crossOrigin = 'anonymous';
  gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.GA_TRACKING_ID;
  document.head.prepend(gtagScript);
  gtag('config', environment.GA_TRACKING_ID);
} */
}