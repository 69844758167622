// Angular Core Modules
import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { SharedService } from './shared.service';

// Services

@Injectable()
export class AuthGuard implements CanActivate {

    loggedInUser: any;

    constructor(
        private router: Router,
        private sharedService: SharedService
    ) { }

    canActivate() {
        if (this.sharedService.getLoggedInUser() && this.sharedService.getToken()) {
            // logged in so return true
            return true;
        }
        // not logged in so redirect to login page
        this.router.navigate(['/login']);
        return false;
    }
}
