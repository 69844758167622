import { EventEmitter, Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
declare var encryptor: any;
@Injectable({
  providedIn: 'root'
})
export class SharedService {
  profilePicLink: any;
  encryptionKey: any = "34 66 36 65 36 35 35 35 36 63 37 34 37 32 36 31 35 34 36 35 36 33 36 38 33 32 33 30 33 32 33 30";
  keys: any = '123456$#@$^@1ERF';
  public glbFlags: any = {};
  TOKEN_KEY: string = 'token';
  timeoutFlag: boolean = false;
  USER: string = 'user';
  setTimer: EventEmitter<any> = new EventEmitter();
  isOtpGenFlag: EventEmitter<boolean> = new EventEmitter();
  toggleFlag: EventEmitter<boolean> = new EventEmitter();
  showotpFlag: boolean = true;
  selectedSiteCode: string;
  selectedSiteCodeFlag: boolean = false;
  selectedProfilePicFlag: boolean = false;
  selectedSiteCodeValueBs = new BehaviorSubject<string>('');
  profilePicLinkValueBs = new BehaviorSubject<string>('');
  ratingServiceFlag: boolean = false;
  minutes: any;
  seconds: any;
  timerOn: boolean = true;
  showTimer: boolean = false;
  siteCode: any;
  userProfileType:any;
  public missionHapinessSurveyList:any;
  public loginUserData:any;
  public dataList: any = [];
  public similarVideosList: any = [];
  constructor() {
    this.selectedSiteCode;
    this.selectedSiteCodeFlag;
    this.selectedProfilePicFlag;
    this.profilePicLink;
    this.profilePicLinkValueBs.next(this.profilePicLink);
    this.selectedSiteCodeValueBs.next(this.selectedSiteCode);
  }

  ngOnInit() {
    this.glbFlags = {
      'mobileSideBar': false
    }
  }

  setToggleFlag(value) {
    this.glbFlags = value
  }
  getToggleFlag() {
    return this.glbFlags;
  }
  getTierUserName() {
    const userInfo = JSON.parse(sessionStorage.getItem(this.USER));
    if (userInfo.userType == 'TIER-1') {
      return 'Tier-1';
    } else if (userInfo.userType == 'TIER-2') {
      return 'Tier-2';
    }
  }

  updateProfilePic(val) {
    if (val) { this.selectedProfilePicFlag = true; }
    this.profilePicLink = val;
    this.profilePicLinkValueBs.next(this.profilePicLink);
  }
  updateselectedSiteCode(val) {
    if (val) { this.selectedSiteCodeFlag = true; }
    this.selectedSiteCode = val;
    this.selectedSiteCodeValueBs.next(this.selectedSiteCode);
  }
  setToken(value: any) {
    sessionStorage.setItem(this.TOKEN_KEY, value);
  }

  getToken() {
    return sessionStorage.getItem(this.TOKEN_KEY);
  }
  removeUser() {
    sessionStorage.removeItem(this.USER);
    sessionStorage.clear();
  }
  removeToken() {
    sessionStorage.removeItem(this.TOKEN_KEY);
    sessionStorage.clear();
  }
  getLoggedInUser() {
    return JSON.parse(sessionStorage.getItem(this.USER));
  }
  setLoggedInUser(user: any) {
    sessionStorage.setItem(this.USER, JSON.stringify(user));
  }
  setSessionTimeout(flag: boolean) {
    this.timeoutFlag = flag;
  }

  getSessionTimeout() {
    return this.timeoutFlag;
  }

  setShowOTPFlag(value: any) {
    this.showotpFlag = value;
  }
  getShowOTPFlag() {
    return this.showotpFlag;
  }

  set(value: any) {
    var key = CryptoJS.enc.Utf8.parse(this.keys);
    var iv = CryptoJS.enc.Utf8.parse(this.keys);
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });

    return encrypted.toString();
  }

  get(value: any) {
    var key = CryptoJS.enc.Utf8.parse(this.keys);
    var iv = CryptoJS.enc.Utf8.parse(this.keys);
    var decrypted = CryptoJS.AES.decrypt(value, key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }
  encryptData(data: any) {
    return encryptor.encryptData(data, this.encryptionKey);
  }

  decryptData(data: any) {
    return encryptor.decryptData(data, this.encryptionKey);
  }

  setRateServiceFlag(flag: boolean) {
    this.ratingServiceFlag = flag;
  }

  getRateSErviceFlag() {
    return this.ratingServiceFlag;
  }

  setSiteCode(siteCode: any) {
    return this.siteCode = siteCode;
  }

  getSiteCode() {
    return this.siteCode;
  }
  setSessionSiteCode(siteCode: any) {
    return sessionStorage.setItem('s', JSON.stringify(siteCode));
  }

  getSessionSiteCode() {
    return JSON.parse(sessionStorage.getItem('s'));
  }

  setSimilarVideosList(list: any) {
    this.similarVideosList = list;
  }

  getSimilarVideoList() {
    return this.similarVideosList;
  }

  getUserProfileType () {
    return this.userProfileType;
  }

  decryptSiteData(response: any) {
    this.userProfileType = response.userProfileType ? this.decryptData(response.userProfileType) : '';
    let emailId = this.decryptData(response.emailId);
    let fullName = this.decryptData(response.fullName);
    let mobileNo = this.decryptData(response.mobileNo);
    if (response.designation) {
      let designation = this.decryptData(response.designation);
      response.designation = designation;
    }
    response.emailId = emailId;
    response.fullName = fullName;
    response.mobileNo = mobileNo;
    if (response.profilePicLink) {
      let profilePicLink = this.decryptData(response.profilePicLink);
      response.profilePicLink = profilePicLink;
    }
    if (response.mappedSites) {
      response.mappedSites.forEach(element => {
        if (element.siteName) {
          let decryptSiteName = this.decryptData(element.siteName);
          element.siteName = decryptSiteName;
        }
        if (element.groupCustCode) {
          let decryptGroupCustCode = this.decryptData(element.groupCustCode);
          element.groupCustCode = decryptGroupCustCode;
        }
        if (response.userType != "Tier-2") {
          if (element['staffUserList']) {
            element['staffUserList'].forEach(element1 => {
              if (element1.mobileNo) {
                let decryptMobileNo = this.decryptData(element1.mobileNo);
                element1.mobileNo = decryptMobileNo;
              }
              if (element1.profilePicLink) {
                let decryptProfilePicLink = this.decryptData(element1.profilePicLink);
                element1.profilePicLink = decryptProfilePicLink;
              }
            });
          }
        }
      });
    }
  }
  b64toBlob(b64Data, contentType) {
    contentType = contentType || '';
    var sliceSize = sliceSize || 512;
  
    var byteCharacters = atob(b64Data);
    var byteArrays = [];
  
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
  
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      var byteArray = new Uint8Array(byteNumbers);
  
      byteArrays.push(byteArray);
    }
  
    var blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }
  base64ArrayBuffer(arrayBuffer) {
    var base64    = ''
    var encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'
  
    var bytes         = new Uint8Array(arrayBuffer)
    var byteLength    = bytes.byteLength
    var byteRemainder = byteLength % 3
    var mainLength    = byteLength - byteRemainder
  
    var a, b, c, d
    var chunk
  
    // Main loop deals with bytes in chunks of 3
    for (var i = 0; i < mainLength; i = i + 3) {
      // Combine the three bytes into a single integer
      chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2]
  
      // Use bitmasks to extract 6-bit segments from the triplet
      a = (chunk & 16515072) >> 18 // 16515072 = (2^6 - 1) << 18
      b = (chunk & 258048)   >> 12 // 258048   = (2^6 - 1) << 12
      c = (chunk & 4032)     >>  6 // 4032     = (2^6 - 1) << 6
      d = chunk & 63               // 63       = 2^6 - 1
  
      // Convert the raw binary segments to the appropriate ASCII encoding
      base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d]
    }
  
    // Deal with the remaining bytes and padding
    if (byteRemainder === 1) {
      chunk = bytes[mainLength]
  
      a = (chunk & 252) >> 2 // 252 = (2^6 - 1) << 2
  
      // Set the 4 least significant bits to zero
      b = (chunk & 3)   << 4 // 3   = 2^2 - 1
  
      base64 += encodings[a] + encodings[b] + '=='
    } else if (byteRemainder === 2) {
      chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1]
  
      a = (chunk & 64512) >> 10 // 64512 = (2^6 - 1) << 10
      b = (chunk & 1008)  >>  4 // 1008  = (2^6 - 1) << 4
  
      // Set the 2 least significant bits to zero
      c = (chunk & 15)    <<  2 // 15    = 2^4 - 1
  
      base64 += encodings[a] + encodings[b] + encodings[c] + '='
    }
    
    return base64
  }
}


