import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  currentMessage = new BehaviorSubject(null);
  notification: any;
  prevTocken: any;
  constructor(private angularFireMessaging: AngularFireMessaging) {
    this.angularFireMessaging.messages.subscribe(
      (_messaging:any) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    )
  }
 
  registerServiceWorker() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/firebase-messaging-sw.js')
        .then((registration) => {
          console.log('Service Worker registered with scope:', registration.scope);
        })
        .catch((error) => {
          console.error('Service Worker registration failed:', error);
        });
    }
  }

  requestPermission(userid: any) {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        this.prevTocken = '';
        this.prevTocken = token;
        return token;
      },
      (err) => {
      }
    );
  }
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        this.notification.push(payload['data']);
        this.currentMessage.next(payload);
      })
  }
  getNotification() {
    return this.notification;
  }
  getToken() {
    this.prevTocken = (this.prevTocken==null)?"eabkCbxufAkYKGVbtOb_02:APA91bFZEDud2UXWwmDYCRt41eFXXABpIkQBbeGRcPA-VL-WcAffmBGN6553H5ZenmlGGE83p0Bof89oTkVWu1udzcqkk4bMw5s0k-znM-oGubC_fOA32cI":this.prevTocken;
      return this.prevTocken;
  }
}
