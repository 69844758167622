import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS, HttpClientJsonpModule, } from '@angular/common/http';
import { DecimalPipe } from '@angular/common';
import { ErrorInterceptorService } from './core/error-interceptor.service';
import { GeneralLayoutComponent } from './layouts/general-layout/general-layout.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { HeaderComponent } from './layouts/header/header.component';
import { LeftNavigationComponent } from './layouts/left-navigation/left-navigation.component';
import { LoginLayoutComponent } from './layouts/login-layout/login-layout.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ServiceUrlConstants } from './constants/service-url-constants';
import { EmbedVideo } from 'ngx-embed-video';
import { ModalModule } from 'ngx-bootstrap/modal';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxLoadingModule } from 'ngx-loading';
import { MatSidenavModule } from '@angular/material/sidenav';
import { CustomMaterialModule } from 'material.module';
import { NgImageSliderModule } from 'ng-image-slider';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { ToastrModule } from 'ngx-toastr';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MessagingService } from './shared/services/messaging.service';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { AuthGuard } from './shared/auth.guard';
import { environment } from '../environments/environment';
// import { NgGoogleAnalyticsModule } from "ng-google-analytics";
import {GoogleAnalyticsService} from "./core/google.analytics.service";
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http), ServiceUrlConstants.jsonFileBasePath, '.json';
}
@NgModule({
  declarations: [
    AppComponent,
    LoginLayoutComponent,
    GeneralLayoutComponent,
    FooterComponent,
    HeaderComponent,
    LeftNavigationComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    HttpClientJsonpModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp({
      apiKey: "AIzaSyAKbj17KxtJANeK8WbBcvN1sSnjyXs_D7Y",
      authDomain: "keyaccount-54ab2.firebaseapp.com",
      databaseURL: "https://keyaccount-54ab2-default-rtdb.firebaseio.com",
      projectId: "keyaccount-54ab2",
      storageBucket: "keyaccount-54ab2.appspot.com",
      messagingSenderId: "193517652831",
      appId: "1:193517652831:web:7037eeea9c6ac1219284ea",
      measurementId: "G-S9R00P1MZR"
    }),
    NgImageSliderModule,
    MatTooltipModule,
    IvyCarouselModule,
    NgSelectModule,
    MatDialogModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => new TranslateHttpLoader(http, ServiceUrlConstants.jsonFileBasePath, '.json'),
        deps: [HttpClient]
      }
    }),
    EmbedVideo.forRoot(),
    ModalModule.forRoot(),
    MatTabsModule,
    BrowserAnimationsModule,
    NgxLoadingModule.forRoot({}),
    MatSidenavModule,
    CustomMaterialModule,
    ToastrModule.forRoot({
      maxOpened: 1,
      preventDuplicates: true,
      autoDismiss: true,
      positionClass: 'toast-top-right',
      closeButton: true
    }), // ToastrModule added
    // NgGoogleAnalyticsModule.forRoot({ id: environment.GA_TRACKING_ID }),
    // NgGoogleAnalyticsModule.forRoot({ id: "UA-228216417-1" }),
  ],
  providers: [DecimalPipe, {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptorService,
    multi: true
  },AuthGuard, MessagingService,GoogleAnalyticsService],
  bootstrap: [AppComponent]
})
export class AppModule { }
