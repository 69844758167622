import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { SharedService } from '../shared/shared.service';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiDataService {
  private httpOptions: any;
  constructor(private http: HttpClient, private shareService: SharedService, private _router: Router,) { }

  setJsonHeader = () => {
    if ((localStorage.getItem('token') !== '') && (localStorage.getItem('token') !== null) && localStorage.getItem('token')) {
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };
    } else {
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
    }
  }

  get = (url: string, options?: any): Observable<any> => {
    this.setJsonHeader();
    this.httpOptions = options;
    return this.http.get(url, this.httpOptions).pipe(catchError(err => this.handleError(err)));
  }

  post = (url: string, body: string, options?: any) => {
    // this.setJsonHeader();
    this.httpOptions = options
    return this.http.post(url, body, this.httpOptions).pipe(catchError(err => this.handleError(err)));
  }
  post1 = (url: string, body: string, options?: any) => {
    // this.setJsonHeader();
    this.httpOptions = options
    return this.http.post(url, body, this.httpOptions).pipe(catchError(err => this.handleError1(err)));
  }
  put = (url: string, body: string, options?: any) => {
    this.setJsonHeader();
    this.httpOptions = options;
    return this.http.put(url, body, this.httpOptions).pipe(catchError(err => this.handleError(err)));
  }

  delete = (url: string, options?: any) => {
    this.setJsonHeader();
    return this.http.delete(url, this.httpOptions).pipe(catchError(err => this.handleError(err)));
  }

  // Error Handler
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);

      if (error.status == 401) {
        this.shareService.removeToken();
        this.shareService.removeUser();
        this._router.navigate(['login']);
      }
      // else if(error.status == 0) {
      //   this.globalService.errorStatus.emit(true);
      // }
      return throwError(error);
    }
    // return an observable with a user-facing error message
    return throwError(
      error);
  };
  private handleError1(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);

      // if (error.status == 401) {
      //   this.shareService.removeToken();
      //   this.shareService.removeUser();
      //   this._router.navigate(['login']);
      // }
      // else if(error.status == 0) {
      //   this.globalService.errorStatus.emit(true);
      // }
      return throwError(error);
    }
    // return an observable with a user-facing error message
    return throwError(
      error);
  };
}
