import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GeneralLayoutComponent } from './layouts/general-layout/general-layout.component';
import { LoginLayoutComponent } from './layouts/login-layout/login-layout.component';

const routes: Routes = [{
  path: '',
  component: LoginLayoutComponent,
  loadChildren: () => import('./features/account/account.module').then(m => m.AccountModule)
},
{
  path: 'order',
  component: GeneralLayoutComponent,
  loadChildren: () => import('./features/order/order.module').then(m => m.OrderModule)
},
{
  path: 'dashboard',
  component: GeneralLayoutComponent,
  loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule)
},
// {
//   path: 'profile',
//   component: GeneralLayoutComponent,
//   loadChildren: () => import('./features/profile/profile.module').then(m => m.ProfileModule)
// },
{
  path: 'profile',
  component: GeneralLayoutComponent,
  loadChildren: () => import('./features/profile-new/profile-new.module').then(m => m.ProfileModule)
},
{
  path: 'contact',
  component: GeneralLayoutComponent,
  loadChildren: () => import('./features/contact/contact.module').then(m => m.ContactModule)
},
{
  path: 'tipsAndTools',
  component: GeneralLayoutComponent,
  loadChildren: () => import('./features/tips-and-tools/tips-and-tools.module').then(m => m.TipsAndToolsModule)
},
{
  path: 'announcement',
  component: GeneralLayoutComponent,
  loadChildren: () => import('./features/announcement/announcement.module').then(m => m.AnnouncementModule)
},
{
  path: 'report',
  component: GeneralLayoutComponent,
  loadChildren: () => import('./features/report/report.module').then(m => m.ReportModule)
},
{ 
  path: 'creditNote', 
  component: GeneralLayoutComponent,
  loadChildren: () => import('./features/credit-note/credit-note.module').then(m => m.CreditNoteModule) 
},
{ 
  path: 'tdsDeclare', 
  component: GeneralLayoutComponent,
  loadChildren: () => import('./features/tds-declare/tds-declare.module').then(m => m.TdsDeclareModule) 
},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
