<header>
    <div class="header">
        <nav class="navbar navbar-expand-md">
            <div class="left-side-head">
                <a id="menu-toggle" class="menu-icon" (click)="toggleSideBar($event)"
                    [ngClass]="{'toggled': sidebarflag}">
                    <div class="bar1"></div>
                    <div class="bar2"></div>
                    <div class="bar3"></div>
                </a>
            </div>

            <div class="collapse navbar-collapse">
                <div class="time-box">
                    <a *ngIf="showTimer">{{minutes}}:{{seconds}} Mins</a>
                </div>
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/profile">
                            <span class="profile-image">
                                <img src={{profilePicLink}} alt="">
                            </span>
                            <span class="nav-text">{{userData?.fullName || "Profile"}}</span>
                        </a>
                    </li>
                    <li class="nav-item bell-notification">
                        <a class="nav-link" href="javascript:void(0)" (click)="toggleNotification($event)">
                            <span class="counter">
                                <i class="fa fa-bell" aria-hidden="true"></i>
                                <sup class="count-color">{{notificationCount}}</sup>
                            </span>
                            <span class="nav-text">Notifications</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="javascript:void(0)" (click)="onLogout()">
                            <i class="fa fa-sign-out" aria-hidden="true"></i>
                            <span class="nav-text">Logout</span>
                        </a>
                    </li>
                </ul>
            </div>

            <div class="bs-notification" [ngClass]="{active: showNotofication}">
                <div class="bs-tabs">
                    <mat-tab-group mat-align-tabs="start" (selectedTabChange)="handleChange($event)">
                        <mat-tab label="UPDATES">
                            <ul class="list-notificacao">
                                <li id='item_notification_1'
                                    [ngClass]="item?.readStatus?'read-notfy':'un-read-notfy'"
                                    *ngFor="let item of notificationList">
                                    <div class="media">
                                        <div class="media-body" (click)="notificationNavigation(item)">
                                            <h4 class="media-heading">{{item.title}}</h4>
                                            <p style="text-align: left;width: 100%;">{{item.message}}</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <div class="noDataTitle" *ngIf="updateErrMsg != ''">
                                <h4 class="title">{{updateErrMsg}}</h4>
                            </div>
                        </mat-tab>
                        <mat-tab label="PROMOTIONAL">
                            <ul class="list-notificacao">
                                <li id='item_notification_1'
                                    [ngClass]="item?.read?'read-notfy':'un-read-notfy'"
                                    *ngFor="let item of notificationList">
                                    <div class="media">
                                        <!-- <div class="media-left">
                                            <a href="#">
                                                <img alt="64x64" class="media-object" data-src="holder.js/64x64"
                                                    src="assets/img/thum.svg" data-holder-rendered="true"> </a>
                                        </div> -->
                                        <div class="media-body" (click)="notificationNavigation(item)">
                                            <h4 class="media-heading">{{item.title}}</h4>
                                            <p>{{item.msgDesc}}
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <div class="noDataTitle" *ngIf="promotionaErrMsg != ''">
                                <h4 class="title">{{promotionaErrMsg}}</h4>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>
        </nav>
    </div>
    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px',fullScreenBackdrop:true }"
        [template]="customLoadingTemplate">
    </ngx-loading>
</header>    