import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceUrlConstants } from 'src/app/constants/service-url-constants';
import { ApiDataService } from 'src/app/core/api-data.service';
import { UserLoginResponse } from 'src/app/Models/UserModel';
import { SharedService } from 'src/app/shared/shared.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { FireBaseNotification } from 'src/app/Models/OrderModel';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  userLoginResponse = new UserLoginResponse();
  token: string | null;
  mobileNo: string;
  private httpOptions: any;
  headers: HttpHeaders;
  constructor(private serviceUrlConstants: ServiceUrlConstants, private _router: Router,
    private apiDataService: ApiDataService, private shareService: SharedService, private http: HttpClient) {
    this.token = '';
    this.token = this.shareService.getToken();
    this.userLoginResponse = this.shareService.getLoggedInUser();
  }
  getLandingPageDetails(data: any,mobileNo:any): Observable<any> {
    this.headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.shareService.getToken(),).set('userName', mobileNo);
    return this.apiDataService.post(this.serviceUrlConstants.getLandingPageDetails, data, { headers: this.headers }).pipe(catchError(err => this.handleError(err)));
  }
  getProfileInfo(data: any,mobileNo:any): Observable<any> {
    this.headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.shareService.getToken(),).set('userName', mobileNo);
    return this.apiDataService.post(this.serviceUrlConstants.getProfileInfo, data, { headers: this.headers }).pipe(catchError(err => this.handleError(err)));
  }

  getCMSRequest(depoId: any, siteCode: any, userType: any) {
    let headers = new HttpHeaders();
    headers = headers.append('depot', depoId);
    headers = headers.append('userType', userType);
    headers = headers.append('siteCode', siteCode.siteCode);
    return this.apiDataService.get(this.serviceUrlConstants.getCMSRequestURL, { headers: headers });
  }
  getCMSContent(requestBody: any) {
    return this.apiDataService.post(this.serviceUrlConstants.getCMSContentURL, requestBody).pipe(catchError(err => this.handleError(err)));
  }

  saveDeviceToken(fireBaseNotificationObj,mobileNo:any) {
    this.headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.shareService.getToken(),).set('userName', mobileNo);;
    return this.apiDataService.post(this.serviceUrlConstants.saveDeviceToken + "/WEB", fireBaseNotificationObj, { headers: this.headers });
  }

  getAllNotifications(data: any, notificationType: any,mobileNo:any,memberSapCode:any) {
    this.headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.shareService.getToken(),).set('userName', mobileNo)
      .set('sapCode', memberSapCode).set('notificationType', notificationType);
    return this.apiDataService.post(this.serviceUrlConstants.getAllNotification, data, { headers: this.headers });
  }

  getUnreadCount(mobileNo:any,memberSapCode:any) {
    this.headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.shareService.getToken(),).set('userName', mobileNo).set('sapCode', memberSapCode)
    .set('mobileNo', mobileNo);
    return this.apiDataService.get(this.serviceUrlConstants.getUnreadCount, { headers: this.headers });
  }
  getmarkAsRead(data: any, notificationId: any,mobileNo:any,memberSapCode:any) {
    this.headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.shareService.getToken(),).set('userName', mobileNo).set('sapCode', memberSapCode);
    return this.apiDataService.put(this.serviceUrlConstants.getmarkAsRead + '/' + notificationId, data, { headers: this.headers });
  }
  getMissionHapinessSurveyList(mobileNo: any) {
    // this.headers = new HttpHeaders().set('mobile_number', mobileNo);
    let header = new HttpHeaders();
    header.set('Access-Control-Allow-Origin', '*');
    return this.apiDataService.get(this.serviceUrlConstants.missionHappinessSurveyList+'?mobile_number='+mobileNo, { headers: header });
    // return this.apiDataService.get(this.serviceUrlConstants.missionHappinessSurveyList+'?mobile_number='+mobileNo,this.headers).pipe(catchError(err => this.handleError(err)));
    // return this.apiDataService.get(this.serviceUrlConstants.missionHappinessSurveyList,mobileNo);
    // return this.apiDataService.get(this.serviceUrlConstants.missionHappinessSurveyList, { headers: this.headers }).pipe(catchError(err => this.handleError(err)));
  }
  missionHappinessReqRes(data: any, mobileNo: any): Observable<any> {
    this.headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.shareService.getToken(),).set('userName', mobileNo);
    return this.apiDataService.post(this.serviceUrlConstants.missionHappinessReqRes, data, { headers: this.headers }).pipe(catchError(err => this.handleError(err)));
  }
  getPoLandingPageDetails(data: any,mobileNo:any): Observable<any> {
    this.headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.shareService.getToken(),).set('userName', mobileNo);
    return this.apiDataService.post(this.serviceUrlConstants.getPOLandingPageDetails, data, { headers: this.headers }).pipe(catchError(err => this.handleError(err)));
  }
  getPODetailsForDashboardGraph(data: any,mobileNo:any): Observable<any> {
    this.headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.shareService.getToken(),).set('userName', mobileNo);
    return this.apiDataService.post(this.serviceUrlConstants.getPODetailsForLandingPage, data, { headers: this.headers }).pipe(catchError(err => this.handleError(err)));
  }

  getLandingPageTodayDataDownload(data: any,mobileNo:any): Observable<any> {
    this.headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.shareService.getToken(),).set('userName', mobileNo);
    return this.apiDataService.post(this.serviceUrlConstants.getLandingPageTodayDataDownload, data, { headers: this.headers, responseType: 'arraybuffer' as 'json' }).pipe(catchError(err => this.handleError(err)));
  }
  // testing

  // uploadData(filedata: any): Observable<any> {
  //   this.headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.shareService.getToken(),)
  //   return this.apiDataService.post(this.serviceUrlConstants.testDownload, filedata, { headers: this.headers }).pipe(catchError(err => this.handleError(err)));   
  // }
  

  // downloadPDF(id:any): Observable<any> {
  //   this.headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.shareService.getToken(),);
  //   return this.apiDataService.get(this.serviceUrlConstants.testUpload+'/'+ id, { headers: this.headers, responseType: 'arraybuffer' as 'json' }).pipe(catchError(err => this.handleError(err)));
  // }


  //


  
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {    
      if (error.status === 403) {
        this.shareService.removeToken();
        this.shareService.removeUser();
        this._router.navigate(['/']);
      }
      return throwError(error);
    }
    return throwError(
      error);
  };
}
