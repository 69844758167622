import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceUrlConstants } from 'src/app/constants/service-url-constants';
import { ApiDataService } from 'src/app/core/api-data.service';
import { SharedService } from 'src/app/shared/shared.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GetProfileData, UserData, MappedSites, StaffUserList, UserLoginResponse } from 'src/app/Models/ProfileModel';
@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  userLoginResponse = new UserLoginResponse();
  token: string | null;
  mobileNo: string;
  private httpOptions: any;
  headers: HttpHeaders;
  getProfileData: GetProfileData;
  profileData: any;
  userData: UserData;
  mappedSites: MappedSites[];
  staffUserList: StaffUserList[] = [];
  mappedSiteFlag: boolean = false;
  staffUserListArray: StaffUserList[] = [];
  tier2UserMblNo: any;

  constructor(private serviceUrlConstants: ServiceUrlConstants, private _router: Router,
    private apiDataService: ApiDataService, private shareService: SharedService, private http: HttpClient) {
    this.token = '';
    this.token = this.shareService.getToken();
    this.userLoginResponse = this.shareService.getLoggedInUser();
  }

  getProfileInfo(data: any, mobileNo: any): Observable<any> {
    this.headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.shareService.getToken(),).set('userName', mobileNo);
    return this.apiDataService.post(this.serviceUrlConstants.getProfileInfo, data, { headers: this.headers }).pipe(catchError(err => this.handleError(err)));
  }
  addStaffUser(data: any, mobileNo: any): Observable<any> {
    this.headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.shareService.getToken(),).set('userName', mobileNo);
    return this.apiDataService.post(this.serviceUrlConstants.addUser, data, { headers: this.headers }).pipe(catchError(err => this.handleError(err)));
  }
  updateStaffUser(data: any, mobileNo: any): Observable<any> {
    this.headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.shareService.getToken(),).set('userName', mobileNo);
    return this.apiDataService.post(this.serviceUrlConstants.updateStaffUser, data, { headers: this.headers }).pipe(catchError(err => this.handleError(err)));
  }
  getParentUserSiteInfo(data: any, mobileNo: any): Observable<any> {
    this.headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.shareService.getToken(),).set('userName', mobileNo);
    return this.apiDataService.post(this.serviceUrlConstants.getParentUserSiteInfo, data, { headers: this.headers }).pipe(catchError(err => this.handleError(err)));
  }
  activateStaffUser(data: any, mobileNo: any) {
    this.headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.shareService.getToken(),).set('userName', mobileNo);
    return this.apiDataService.post(this.serviceUrlConstants.activateStaffUser, data, { headers: this.headers }).pipe(catchError(err => this.handleError(err)));
  }
  deactivateStaffUser(data: any, mobileNo: any) {
    this.headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.shareService.getToken(),).set('userName', mobileNo);
    return this.apiDataService.post(this.serviceUrlConstants.deactivateStaffUser, data, { headers: this.headers }).pipe(catchError(err => this.handleError(err)));
  }
  profilePicUpload(data: any, mobileNo: any) {
    this.headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.shareService.getToken(),).set('userName', mobileNo);
    return this.apiDataService.post(this.serviceUrlConstants.profilePicUpload, data, { headers: this.headers }).pipe(catchError(err => this.handleError(err)));
  }
  profilePicUploadTier2(data: any, mobileNo: any) {
    this.headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.shareService.getToken(),).set('userName', mobileNo);
    return this.apiDataService.post(this.serviceUrlConstants.profilePicUploadTier2, data, { headers: this.headers }).pipe(catchError(err => this.handleError(err)));
  }
  deleteTier2User(data: any, mobileNo: any) {
    this.headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.shareService.getToken(),).set('userName', mobileNo);
    return this.apiDataService.post(this.serviceUrlConstants.deleteTier2User, data, { headers: this.headers }).pipe(catchError(err => this.handleError(err)));
  }
  addTier2UserSite(data: any, mobileNo: any) {
    this.headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.shareService.getToken(),).set('userName', mobileNo);
    return this.apiDataService.post(this.serviceUrlConstants.addTier2UserSite, data, { headers: this.headers }).pipe(catchError(err => this.handleError(err)));
  }
  submitOnS3(filedata: any, mobileNo: any): Observable<any> {
    this.headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.shareService.getToken(),).set('userName', mobileNo);
    return this.apiDataService.post(this.serviceUrlConstants.sumitOnS3, filedata, { headers: this.headers }).pipe(catchError(err => this.handleError(err)));
  }

  logoutAPI(data: any, mobileNo: any): Observable<any> {
    this.headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.shareService.getToken(),).set('userName', mobileNo);
    return this.apiDataService.post(this.serviceUrlConstants.logoutAPIUrl, data, { headers: this.headers }).pipe(catchError(err => this.handleError(err)));
  }


  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      if (error.status === 403) {
        this.shareService.removeToken();
        this.shareService.removeUser();
        this._router.navigate(['/']);
      }
      return throwError(error);
    }
    return throwError(
      error);
  };
}
