<header>
    <div class="header">
        <nav class="navbar navbar-expand-md">
            <div class="left-side-head">
                <a id="menu-toggle" class="menu-icon" (click)="toggleSideBar($event)"
                    [ngClass]="{'toggled': sidebarflag}">
                    <div class="bar1"></div>
                    <div class="bar2"></div>
                    <div class="bar3"></div>
                </a>
            </div>

            <div class="collapse navbar-collapse">
                <div class="time-box">
                    <a *ngIf="showTimer">{{minutes}}:{{seconds}} Mins</a>
                </div>
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/profile">
                            <span class="profile-image">
                                <img src={{profilePicLink}} alt="">
                            </span>
                            <span class="nav-text">{{userData?.fullName || "Profile"}}</span>
                        </a>
                    </li>
                    <li class="nav-item bell-notification">
                        <a class="nav-link" href="javascript:void(0)" (click)="toggleNotification($event)">
                            <span class="counter">
                                <i class="fa fa-bell" aria-hidden="true"></i>
                                <sup class="count-color">{{notificationCount}}</sup>
                            </span>
                            <span class="nav-text">Notifications</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="javascript:void(0)" (click)="onLogout()">
                            <i class="fa fa-sign-out" aria-hidden="true"></i>
                            <span class="nav-text">Logout</span>
                        </a>
                    </li>
                </ul>
            </div>

           
            <div class="bs-notification" [ngClass]="{ active: showNotofication }">
                <div class="bs-tabs">
                    <!-- Tabs Navigation -->
                    <div class="tabs" style="display: flex;">
                        <div class="tab" [ngClass]="{ active: tabIndex === 0 }" (click)="handleChange(0)">
                            UPDATES
                        </div>
                        <div class="tab" [ngClass]="{ active: tabIndex === 1 }" (click)="handleChange(1)">
                            PROMOTIONAL
                        </div>
                    </div>
            
                    <!-- Tab Content -->
                    <div class="tab-content" style="margin-top: -13px;">
                        <!-- UPDATES Tab -->
                        <div *ngIf="tabIndex === 0">
                            <ul class="list-notificacao">
                                <li *ngFor="let item of notificationList" 
                                    [ngClass]="item?.readStatus ? 'read-notfy' : 'un-read-notfy'">
                                    <div class="media">
                                        <div class="media-body" (click)="notificationNavigation(item)">
                                            <h4 class="media-heading">{{ item.title }}</h4>
                                            <p style="text-align: left;width: 100%;">{{ item.message }}</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <div class="noDataTitle" *ngIf="updateErrMsg !== ''">
                                <h4 class="title">{{ updateErrMsg }}</h4>
                            </div>
                        </div>
            
                        <!-- PROMOTIONAL Tab -->
                        <div *ngIf="tabIndex === 1">
                            <ul class="list-notificacao">
                                <li *ngFor="let item of notificationList" 
                                    [ngClass]="item?.read ? 'read-notfy' : 'un-read-notfy'">
                                    <div class="media">
                                        <div class="media-body" (click)="notificationNavigation(item)">
                                            <h4 class="media-heading">{{ item.title }}</h4>
                                            <p>{{ item.msgDesc }}</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <div class="noDataTitle" *ngIf="promotionaErrMsg !== ''">
                                <h4 class="title">{{ promotionaErrMsg }}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            
                    
               
        </nav>
    </div>
    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px',fullScreenBackdrop:true }"
        [template]="customLoadingTemplate">
    </ngx-loading>
</header>    