export class AddUserRequestObject {
    assignedSiteList: SiteList[];
    designation: string;
    emailId: string;
    mobileNo: string;
    name: string;
    userMasterId: number
}
export class SiteList {
    siteId: string;
    groupCustCode: string;
    siteName: string;
}
export class GetProfileData {
    mobileNo: string;
}
export class UserLoginResponse {
    authToken: string;
    emailId: string;
    flaggedUser: boolean=false;
    mobileNo: string;
    userId: number;
    userStatus: string;
    userType?: string;
}
export class UserData {
department?: string;
designation: string;
emailId: string;
flaggedUser: boolean;
fullName: string;
initials?: string;
mappedSites:MappedSites[];
mobileNo: string;
userType: string;
profilePicLink:any;
}
export class MappedSites{
groupCustCode: string;
siteId: string;
siteName: string;
staffUserList: StaffUserList[]
}
export class StaffUserList{
designation: string;
fullName: string;
mobileNo: string;
staffUserId: number;
status: string;
}
export class Record{
    assignedSiteList: SiteList[];
     designation: string;
     emailId: string;
     mobileNo: string;
     contactNo?: string;
     name: string;
     userMasterId?:number;
     staffUserId?: number;
     flaggedUser:boolean;
     viewOnlyUser:boolean;
     userProfileType?: string;
}

export class RecordNewProfile{
    assignedSiteList: SiteList[];
    departMent: string;
     emailId: string;
     mobileNo: string;
     contactNo?: string;
     roleName: string;
     isZonelUser:string;
     zonelUserId:string;
     name: string;
     userMasterId?:number;
     staffUserId?: number;
     flaggedUser:boolean;
     viewOnlyUser:boolean;
     userProfileType?: string;
}
export class GetCustomerInfo{
    customerCode : string;
    companyCode : string;
}
export class GetQuaterCustomerInfo{
    customerCode : string;
    companyCode : string;
    financialYear: string;
    quarter:string;
}