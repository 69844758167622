// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // production
  // GA_TRACKING_ID:"UA-228216417-2",
  // API_URL: 'https://prod.ultratechcustomerconnect.com/mobile/utcl/keyAccount/',
  // CMS_URL:  'https://apiprod.oneultratech.com/web/',
  // Map_share: 'https://prod.sharemap.oneultratech.com/#/map?invoiceNo=',
  // // Banner_URL: 'https://apimaintenance.oneultratech.com:8793/web',
  // Mission_happyNess:'https://www.eye2serve.com/mission-happiness',

  // dev
  // GA_TRACKING_ID:"UA-228216417-1",
  // API_URL: 'https://apidev.ultratechcustomerconnect.com/mobile/utcl/keyAccount/',
  // CMS_URL: 'https://apidev.oneultratech.com/web/',
  // Map_share:'https://qa.sharemap.oneultratech.com/#/map?invoiceNo=',
  // Mission_happyNess:'https://www.eye2serve.com/mission-happiness',
  // Banner_URL: 'https://apimaintenance.oneultratech.com:8793/web',
  
  //QA
  GA_TRACKING_ID:"UA-228216417-1",
  //API_URL: 'https://apiqa.ultratechcustomerconnect.com/mobile/utcl/keyAccount/',
  //API_URL: 'http://qa1.keyaccount.oneultratech.com/mobile/utcl/keyaccount/',
  API_URL: 'https://qakeyaccount.oneultratech.com/mobile/utcl/keyAccount/',
  CMS_URL:  'https://apiqa.oneultratech.com/web/',  
  Map_share:'https://qa.sharemap.oneultratech.com/#/map?invoiceNo=',
  //Mission_happyNess:'http://mhqa.ultratechcustomerconnect.com/',
  Mission_happyNess:'https://www.eye2serve.com/mission-happiness',
  // Banner_URL: 'https://apimaintenance.oneultratech.com:8793/web',

  firebase: {
    apiKey: "AIzaSyAKbj17KxtJANeK8WbBcvN1sSnjyXs_D7Y",
    authDomain: "keyaccount-54ab2.firebaseapp.com",
    databaseURL: "https://keyaccount-54ab2-default-rtdb.firebaseio.com",
    projectId: "keyaccount-54ab2",
    storageBucket: "keyaccount-54ab2.appspot.com",
    messagingSenderId: "193517652831",
    appId: "1:193517652831:web:7037eeea9c6ac1219284ea",
    measurementId: "G-S9R00P1MZR"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
