import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  currentMessage = new BehaviorSubject(null);
  notification: any;
  prevTocken: any;
  constructor(private angularFireMessaging: AngularFireMessaging) {
    this.angularFireMessaging.messages.subscribe(
      (_messaging:any) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    )
  }
  requestPermission(userid: any) {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        this.prevTocken = '';
        this.prevTocken = token;
        return token;
      },
      (err) => {
      }
    );
  }
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        this.notification.push(payload['data']);
        this.currentMessage.next(payload);
      })
  }
  getNotification() {
    return this.notification;
  }
  getToken() {
      return this.prevTocken;
  }
}
