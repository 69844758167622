import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError,of } from 'rxjs';
import { ServiceUrlConstants } from 'src/app/constants/service-url-constants';
import { ApiDataService } from 'src/app/core/api-data.service';
import { SharedService } from 'src/app/shared/shared.service';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  headers: HttpHeaders;
  constructor(private apiDataService: ApiDataService, private serviceUrlConstants: ServiceUrlConstants,
    private globalService: SharedService, private _router: Router , private http : HttpClient) { }

  isFirstTimeLogin(data: any): Observable<any> {
    return this.apiDataService.post(this.serviceUrlConstants.isFirstTimeLogin, data);
  }
  generateOtp(data: any): Observable<any> {
    return this.apiDataService.post(this.serviceUrlConstants.generateOtp, data);
  }
  validateOtp(data: any): Observable<any> {
    return this.apiDataService.post(this.serviceUrlConstants.validateOtp, data);
  }
  createPassword(data: any): Observable<any> {
    return this.apiDataService.post1(this.serviceUrlConstants.createPassword, data);
  }
  login(data: any): Observable<any> {
    return this.apiDataService.post(this.serviceUrlConstants.login, data);
  }
  resendOtp(data: any): Observable<any> {
    return this.apiDataService.post(this.serviceUrlConstants.resendOtp, data);
  }
  resetPassword(data: any): Observable<any> {
    return this.apiDataService.post(this.serviceUrlConstants.resetPassword, data);
  }
  getOTPByMobileNo(data: any): Observable<any> {
    return this.apiDataService.post(this.serviceUrlConstants.getOTPByMobileNo, data);
  }
  getUserRoleProfile(data: any, mobileNo: any): Observable<any> {
    this.headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.globalService.getToken(),).set('userName', mobileNo);
    return this.apiDataService.post(this.serviceUrlConstants.getUserRoleProfile, data, { headers: this.headers }).pipe(catchError(err => this.handleError(err)));
  }
  requestTestCert(data: any, mobileNo:any): Observable<any> {
    this.headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.globalService.getToken(),).set('userName', mobileNo);
    return this.apiDataService.post(this.serviceUrlConstants.getTestCertificateSiteWise, data, { headers: this.headers }).pipe(catchError(err => this.handleError(err)));
  }


  gettestCerticate(data: any, mobileNo:any): Observable<any> {
    this.headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.globalService.getToken(),).set('userName', mobileNo);
    return this.apiDataService.post(this.serviceUrlConstants.getTestCertificateSiteWise, data, { headers: this.headers }).pipe(catchError(err => this.handleError(err)));
   }

   public getIPAddress()
   {
     //return this.http.get("https://jsonip.com/");
    // return this.http.get("https://api.ipify.org?format=json");
    const response = { ip: '0.0.0.0' };
    return of(response);
   }
  getdetails(data: any): Observable<any> {
    // const headers = {
    //   'Authorization': 'Bearer ' + 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI5OTIzNjA3OTc4IiwiZXhwIjoxNjEyNTM4MzQ4LCJpYXQiOjE2MTI1MzQ3NDh9.igYREMbUBKehD4WTZA-xPCVAE05vv6fCA8IBxTmzeek',
    //   'userName': '9923607978'
    // };

    const headers1 = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI5OTIzNjA3OTc4IiwiZXhwIjoxNjEyNTM4MzQ4LCJpYXQiOjE2MTI1MzQ3NDh9.igYREMbUBKehD4WTZA-xPCVAE05vv6fCA8IBxTmzeek',
        'userName': '9923607978'
      })
    };
    return this.apiDataService.post(this.serviceUrlConstants.testingURL, data, headers1);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      if (error.status === 403) {
        this.globalService.removeToken();
        this.globalService.removeUser();
        this._router.navigate(['/']);
      }
      return throwError(error);
    }
    return throwError(
      error);
  };


}
