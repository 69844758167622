// Angular Core Modules
import { Injectable } from '@angular/core';
// Environment Configs
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ServiceUrlConstants {
    public static reqUrl: string = environment.API_URL;
    public static cmsReqUrl: string = environment.CMS_URL;
    public static bannerReqUrl: string = environment.CMS_URL;
    public static jsonFileBasePath = 'assets/i18n/';
    public static missionHappyNessSurvey: string = environment.Mission_happyNess;


    public isFirstTimeLogin: string = ServiceUrlConstants.reqUrl + 'users/isFirstTimeLogin';
    public generateOtp: string = ServiceUrlConstants.reqUrl + 'users/generateOtp';
    public validateOtp: string = ServiceUrlConstants.reqUrl + 'users/validateOtp';
    public createPassword: string = ServiceUrlConstants.reqUrl + 'users/createPassword';
    public login: string = ServiceUrlConstants.reqUrl + 'users/login';
    public resendOtp: string = ServiceUrlConstants.reqUrl + 'users/resendOtp';
    public resetPassword: string = ServiceUrlConstants.reqUrl + 'users/resetPassword';
    public getOTPByMobileNo: string = ServiceUrlConstants.reqUrl + 'users/getOTPByMobileNo';


    public testingURL: string = ServiceUrlConstants.reqUrl + 'orders/getDispatchedOrders';
    public missionHappinessReqRes: string = ServiceUrlConstants.reqUrl + 'orders/missionHappinessReqRes';
    public getPOLandingPageDetails: string = ServiceUrlConstants.reqUrl + 'orders/getPOLandingPageDetails';
    public getPODetailsForLandingPage: string = ServiceUrlConstants.reqUrl + 'orders/getPODetailsForLandingPage';

    // Credit Note Service
    public getCreditNote: string = ServiceUrlConstants.reqUrl + 'orders/getCreditNote';
    public downLoadCreditNote: string = ServiceUrlConstants.reqUrl + 'orders/getShortageDetailsDownload';
    public getShortageDetails: string = ServiceUrlConstants.reqUrl + 'orders/getShortageDetails';


    // Order Service
    public dispatchOrderUrl: string = ServiceUrlConstants.reqUrl + 'order/listOfOrders';
   //  public dispatchOrderUrlNew: string = ServiceUrlConstants.reqUrl + 'orders/getDispatchedOrders';
   public dispatchOrderUrlNew: string = ServiceUrlConstants.reqUrl + 'orders/getRsnDispatchedOrders';
    public getOrderByInvoiceNo: string = ServiceUrlConstants.reqUrl + 'orders/getInvoiceDetails';
    public getPendingEpodOrders: string = ServiceUrlConstants.reqUrl + 'order/getPendingEpodOrders';
    // public getPendingEpodOrdersNew: string = ServiceUrlConstants.reqUrl + 'orders/getPendingEpodOrders';
    public getPendingEpodOrdersNew: string = ServiceUrlConstants.reqUrl + 'orders/getRSNPendingEpodOrders';

    public getCompletedEpodOrders: string = ServiceUrlConstants.reqUrl + 'order/getCompletedEpodOrders';
    // public getCompletedEpodOrdersNew: string = ServiceUrlConstants.reqUrl + 'orders/getCompletedEpodOrders';
    public getCompletedEpodOrdersNew: string = ServiceUrlConstants.reqUrl + 'orders/getRSNCompletedEpodOrders';
    // public getInvoivecopy: string = ServiceUrlConstants.reqUrl + 'orders/getInvoivecopy';
    //public downloadInvoivecopy: string = ServiceUrlConstants.reqUrl + 'orders/downloadInvoivecopy';

    // Epod 
    public generateOTPForEPOD: string = ServiceUrlConstants.reqUrl + 'orders/generateOtpForEPOD';
    public validateOTPForEPOD: string = ServiceUrlConstants.reqUrl + 'orders/validateOtpForEPOD';
    public sumitOnS3: string = ServiceUrlConstants.reqUrl + 'orders/uploadDocuments';

    // public getEpodDetails: string = ServiceUrlConstants.reqUrl + 'orders/getEpodDetails';
    public getEpodDetails: string = ServiceUrlConstants.reqUrl + 'orders/getRsnEpodDetails';
    // public submitEpod: string = ServiceUrlConstants.reqUrl + 'orders/submitEpod';
    public submitEpod: string = ServiceUrlConstants.reqUrl + 'orders/submitRsnEpod';
    // feedback
    public getFeedbackOptions: string = ServiceUrlConstants.reqUrl + 'orders/getFeedbackOptions';
    // public submitFeedbackForOrder: string = ServiceUrlConstants.reqUrl + 'orders/submitFeedbackForOrder';
    public submitFeedbackForOrder: string = ServiceUrlConstants.reqUrl + 'orders/submitRsnFeedbackForOrder';
    public getRatingDetails: string = ServiceUrlConstants.reqUrl + 'orders/getRatingDetails';
    public getLandingPageDetails: string = ServiceUrlConstants.reqUrl + 'orders/getLandingPageDetails';
    public getITrack: string = ServiceUrlConstants.reqUrl + 'orders/getITrack';
    public requestTestCertUrl: string = ServiceUrlConstants.reqUrl + 'orders/requestTestCert';
    public getEpodPendingPercentage: string = ServiceUrlConstants.reqUrl + 'orders/getEpodPendingPercentage';

    // profile 
    public getProfileInfo: string = ServiceUrlConstants.reqUrl + 'profile/getProfileInfo';
    public getUserRoleProfile: string = ServiceUrlConstants.reqUrl + 'profile/getUserRoleProfile';
    public getGroupProfileInfo: string = ServiceUrlConstants.reqUrl + 'profile/getZonalGroupUserProfileInfo';
    public getZonalProfileUserInfo: string = ServiceUrlConstants.reqUrl + 'profile/getZonalProfileUserInfo';
    public getSiteProfileUserInfo: string = ServiceUrlConstants.reqUrl + 'profile/getSiteProfileUserInfo';
    public getUserDetailsExcelDownload: string = ServiceUrlConstants.reqUrl + 'profile/getUserDetailsExcelDownload';
    public getUnassignedSiteInfo: string = ServiceUrlConstants.reqUrl + 'profile/getUnassignedSiteInfo';
    public getUnassignedSiteCount: string = ServiceUrlConstants.reqUrl + 'profile/getUnassignedSiteCount';
    public getUsersRolesAndDepartment: string = ServiceUrlConstants.reqUrl + 'profile/getUsersRolesAndDepartment';
    public addUser: string = ServiceUrlConstants.reqUrl + 'profile/addStaffUser';
    public newAddUser: string = ServiceUrlConstants.reqUrl + 'profile/addZonalStaffUser';
    public editStaffUser: string = ServiceUrlConstants.reqUrl + 'profile/editStaffUser';
    public removeTier2User: string = ServiceUrlConstants.reqUrl + 'profile/removeTier2User';
    public activateStaffUser = ServiceUrlConstants.reqUrl + 'profile/activateStaffUser';
    public deactivateStaffUser = ServiceUrlConstants.reqUrl + 'profile/deactivateStaffUser';
    public profilePicUpload = ServiceUrlConstants.reqUrl + 'users/uploadProfilePicture';
    public profilePicUploadTier2 = ServiceUrlConstants.reqUrl + 'profile/uploadProfilePicture';
    public deleteTier2User = ServiceUrlConstants.reqUrl + 'profile/deleteTier2User';
    public addTier2UserSite = ServiceUrlConstants.reqUrl + 'profile/addTier2UserSite';
    public removeTier2SiteUser = ServiceUrlConstants.reqUrl + 'profile/removeTier2SiteUser';
    public updateStaffUser = ServiceUrlConstants.reqUrl + 'profile/UpdateStaffUser';
    public getParentUserSiteInfo = ServiceUrlConstants.reqUrl + 'profile/getParentUserSiteInfo';
    public getZonalGroupUserCreatingSiteUserInfo: string = ServiceUrlConstants.reqUrl + 'profile/getZonalGroupUserCreatingSiteUserInfo';
    //mapMuindia itrack apis
    // public getMapMyIndiaResURL1: string = 'https://apis.mapmyindia.com/advancedmaps/v1/28f9jdya2q9x3qqgyoo99134smf3x6jb/route_adv/driving/';
    // public getMapMyIndiaResURL2: string = '?alternatives=false&&geometries=polyline&overview=full&exclude=&steps=true&region=ind';

    public getMapMyIndiaResURL1: string ='https://apis.mappls.com/advancedmaps/v1/636a355bc88cd60029d57f6ac616c2de/route_adv/driving/';
    public getMapMyIndiaResURL2: string = '?alternatives=false&&geometries=polyline&overview=full&exclude=&steps=true&region=ind';
    // CMS integration
    public getCMSContentURL: string = ServiceUrlConstants.cmsReqUrl + 'keycms/showCmsContent';
    public getCMSRequestURL: string = ServiceUrlConstants.cmsReqUrl + 'keycms/showCmsDetailsByDepo';
    public getAllTipsURL: string = ServiceUrlConstants.cmsReqUrl + 'keytipsandtools/getAllTips';
    public getAllVideosURL: string = ServiceUrlConstants.cmsReqUrl + 'keytipsandtools/getAllVideos';

    //  Announcements
    public getAllAnnouncementsURL: string = ServiceUrlConstants.cmsReqUrl + 'keytipsandtools/announcements/getAllAnnouncements';
    public getAboutUsURL: string = ServiceUrlConstants.cmsReqUrl + 'keytipsandtools/announcements/getAboutUs';
    public getAllContestsNSurvey: string = ServiceUrlConstants.cmsReqUrl + 'keytipsandtools/announcements/showContestAndSurvey';
    public getWinnersURL: string = ServiceUrlConstants.cmsReqUrl + 'keyadmin/winners';
    
    // Announcements Survey
    //public missionHappinessSurveyList: string = ServiceUrlConstants.missionHappyNessSurvey + 'mission-happiness-qa/ut/send-survey-api';
    public missionHappinessSurveyList: string = ServiceUrlConstants.missionHappyNessSurvey + '/ut/get-mh-survey-api';
    public addSurveyNpsScoreApi: string = ServiceUrlConstants.missionHappyNessSurvey + '/ut/add-survey-nps-score-api';

    //   FCM Token
    public saveDeviceToken: string = ServiceUrlConstants.reqUrl + 'users/saveDeviceToken';
    // notification
    public getAllNotification: string = ServiceUrlConstants.reqUrl + 'notification/getAllNotificationOfUser'
    public getUnreadCount: string = ServiceUrlConstants.reqUrl + 'notification/getUnreadCount';
    public getmarkAsRead: string = ServiceUrlConstants.reqUrl + 'notification/markAsRead';

    // logout
    public logoutAPIUrl: string = ServiceUrlConstants.reqUrl + 'users/logout';


    // Reports
    public ledgerReportURl: string = ServiceUrlConstants.reqUrl + 'report/LedgerStatementDownload';
    public outStandingReportURl: string = ServiceUrlConstants.reqUrl + 'report/outstandingReportsDownload';
    public getStateListURl: string = ServiceUrlConstants.reqUrl + 'report/getUserStates';
    public getStateWiseSiteURl: string = ServiceUrlConstants.reqUrl + 'report/getUserSites';
    public getTier2StateWiseSiteURl: string = ServiceUrlConstants.reqUrl + 'report/getTier2UserSites';
    public getinvoiceSummaryReportDownload: string = ServiceUrlConstants.reqUrl + 'report/invoiceSummaryReportDownload';
    public getinvoiceSummaryReport: string = ServiceUrlConstants.reqUrl + 'report/getInvoiceSummaryReport';
    public getTestCertificateSiteWise: string = ServiceUrlConstants.reqUrl + 'report/getTestCertificateSiteWise';
    public getBgSummaryReportDownload: string = ServiceUrlConstants.reqUrl + 'orders/getBgStatusDownload';
    public getBgSummaryReport: string = ServiceUrlConstants.reqUrl + 'orders/getBgStatus';
    public getUnpaidInvoices: string = ServiceUrlConstants.reqUrl + 'report/getUnpaidInvoices';
    public getPOSummaryDetails: string = ServiceUrlConstants.reqUrl + 'orders/getPOSummaryDetails';
    public downloadUnpaidInvExcel: string = ServiceUrlConstants.reqUrl + 'report/downloadUnpaidInvExcel';
    public downloadPoExcel: string = ServiceUrlConstants.reqUrl + 'orders/getPOSummaryDetailsDownload';
    public downloadInvoivecopy: string = ServiceUrlConstants.reqUrl + 'orders/downloadInvoivecopy';
    
    // public testDownload: string = ServiceUrlConstants.cmsReqUrl + 'finance/document';
    // public testUpload: string = ServiceUrlConstants.cmsReqUrl + 'finance/document';

    public getCustomercode: string = ServiceUrlConstants.reqUrl + 'orders/getCustomerCodes';

    public getReversalDataYearWise:string =ServiceUrlConstants.reqUrl + 'orders/getReversalDataYearWise';

    public getReversalDataQuarterWise:string =ServiceUrlConstants.reqUrl + 'orders/getReversalDataQuarterWise';

    public confirmReversalDataYearWise:string =ServiceUrlConstants.reqUrl + 'orders/confirmReversalDataYearWise';

    public getFinancialYear:string =ServiceUrlConstants.reqUrl + 'orders/getFinancialYear';

    public downloadGSTPdfYearWise:string=ServiceUrlConstants.reqUrl + 'orders/downloadGSTPdfYearWise';

    public downloadGSTPdfQuarterWise:string=ServiceUrlConstants.reqUrl + 'orders/downloadGSTPdfQuarterWise';

    public sendGSTPdfYearWiseEmail:string=ServiceUrlConstants.reqUrl + 'orders/sendGSTPdfYearWiseEmail';
    
    public confirmReversalDataQuaterWise:string=ServiceUrlConstants.reqUrl + 'orders/confirmReversalDataQuaterWise';
    public sendGSTPdfQuarterWiseEmail:string=ServiceUrlConstants.reqUrl + 'orders/sendGSTPdfQuarterWiseEmail';
    public getTdsReversalData:string=ServiceUrlConstants.reqUrl + 'orders/getTdsReversalData';
    public confirmTdsReversalData:string=ServiceUrlConstants.reqUrl + 'orders/confirmTdsReversalData';

   // public getCustomerPanNumbers:string=ServiceUrlConstants.reqUrl + 'orders/getCustomerPanNumbers';
    public downloadTDSPdf :string=ServiceUrlConstants.reqUrl + 'orders/downloadTDSPdfFromS31';
    public getCustomerPanNumbers:string=ServiceUrlConstants.reqUrl + 'orders/getTdsReversalDataByFyear';
    public getFinancialYearGstYearWise:string=ServiceUrlConstants.reqUrl + 'orders/getFinancialYearGstYearWise';
    public getCustomerPanNoGstYearWise:string=ServiceUrlConstants.reqUrl + 'orders/getCustomerPanNoGstYearWise';
    public getGstReversalDataYearWise:string=ServiceUrlConstants.reqUrl + 'orders/getGstReversalDataYearWise';
    public generateOtpForTds:string=ServiceUrlConstants.reqUrl + 'orders/generateOtpForTds';
    public validateOtpForTds:string=ServiceUrlConstants.reqUrl + 'orders/validateOtpForTds';
    public resendOtpTDS:string=ServiceUrlConstants.reqUrl + 'orders/resendOtp';
    public viewGstYearWise:string=ServiceUrlConstants.reqUrl + 'orders/viewGstYearWise';

    public getFinancialYearGstQuarterwise:string=ServiceUrlConstants.reqUrl+'orders/getFinancialYearGstQuarterwise';
    public getCustomerPanNoGstQuarterWise:string=ServiceUrlConstants.reqUrl+'orders/getCustomerPanNoGstQuarterWise';
    public getReversalDataGstQuarterWise:string=ServiceUrlConstants.reqUrl+'orders/getReversalDataGstQuarterWise';

    public generateOtpForGst:string=ServiceUrlConstants.reqUrl + 'orders/generateOtpForTds';
    public validateOtpForGst:string=ServiceUrlConstants.reqUrl + 'orders/validateOtpForTds';
    public viewGstQuarterWise:string=ServiceUrlConstants.reqUrl + 'orders/viewGstQuarterWise';
    public getLandingPageTodayDataDownload:string=ServiceUrlConstants.reqUrl +'orders/getLandingPageTodayDataDownload';
}