import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserLoginResponse } from 'src/app/Models/UserModel';
import { SharedService } from 'src/app/shared/shared.service';
@Component({
  selector: 'app-left-navigation',
  templateUrl: './left-navigation.component.html',
  styleUrls: ['./left-navigation.component.css']
})
export class LeftNavigationComponent implements OnInit {
  userLoginResponse = new UserLoginResponse();
  usertype:string;
  constructor(public route:ActivatedRoute,private sharedService: SharedService) { }

  ngOnInit(): void {
    this.userLoginResponse = this.sharedService.getLoggedInUser();
    this.usertype=this.userLoginResponse.userType;
   }
}
