export class GetOtpModel {
    expirationTime: string;
    mobileNo: string;
    type: string;
    userId: string;
    firstname: string;
    mode: string;
    ip_address_imei:string;
    url_pagename:string;
}
export class ValidateOtpModel {
    forgotPassFlag: boolean = true;
    fromWeb: boolean = true;
    otp: string;
    type: string;
    userName: string;
    mode: string;
    ip_address_imei:string;
    url_pagename:string;

}
export class GetOtp {
    mobileNo: string;
}
export class CreatePasswordObject {
    password: string;
    registeredMobileNo: string;
    type: string;
    username: string;
    otp:string;
    mode: string;
    ip_address_imei:string;
    url_pagename:string;

}

export class testClass {
    userId: string;
}

export class UserLoginResponse {
    authToken: string;
    emailId: string;
    flaggedUser: boolean = false
    mobileNo: string;
    userId: number
    userStatus: string;
    userType: string;
    depoList: DepoList[];
    memberSapCode: string;
    memberSapName:string;
    viewOnlyUser:boolean;
    // depo: number
}
export class DepoList {
    depoCode: string;
    zoneCode: string;
    regionCode: string;
}
export class LoginRequestObject {
    password: string;
    registeredMobileNo: string;
    type: string;
    username: string;
    mode: string;
    ip_address_imei:string;
    url_pagename:string;

}
export class ResentOtpObject {
    expirationTime: string;
    firstname: string;
    mobileNo: string;
    type: string;
    userId: string;
    mode: string;
    ip_address_imei:string;
    url_pagename:string;
}
export class GetOtpByMobileObject {
    mobileNo?: string;
}
export class GenrateOtpObject {
    mobileNo: string;
    invoiceNo: string;
}
export class NotificationRequestObject {
    serviceDTO: ServiceDTO;
}
export class ServiceDTO {
    notificationType: string;
}
export class logoutObject {
    deviceType: string;
    mobileNo: string;
    mode: string;
    ip_address_imei:string;
    url_pagename:string;

}